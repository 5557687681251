const Noty = require('noty')

document.querySelectorAll('.notification').forEach(e => {
    let label;
    if(e.dataset.label === 'danger'){
        label = 'error';
    } else {
        label = e.dataset.label
    }
    new Noty({
        type: label,
        theme: 'sunset',
        layout: 'topRight',
        text: e.dataset.message,
        progressBar: true,
        timeout: 15000,
    }).show();
})