import '../css/app.scss';

import 'bootstrap';
import './bootstrap-stimulus';
import './bootstrap-material-design';

import Routing from './components/Routing';
import $ from 'jquery';
import 'select2';
import 'select2/dist/js/i18n/fr';
import 'jquery-ui';
import LoadToast from "../../vendor/app/voyancesite-bundle/Resources/public/js/bootstrapToast";

// import "@chase439/material-kit/assets/js/material-kit";

import initCookie from '../../vendor/app/voyancesite-bundle/Resources/public/js/cookie';
import './notifications';

// Menu burger
document.querySelector('.burger-button').addEventListener('click', function () {
    document.querySelector('.animated-icon').classList.toggle('open');
});
$(document).on('click', '.burger-button', function() {
    let htmlElement = document.getElementsByTagName("html")[0];
    if (htmlElement.classList.contains("nav-open")) {
        htmlElement.classList.remove("nav-open");
    } else {
        htmlElement.classList.add("nav-open");
    }
});

initCookie({
    params: {
        general: {
            google_tag_id: "UA-30736500-1",
        },
        languages: {
            fr: {
                consent_modal: {
                    title: '<span class="color_5">Katleen Voyance utilise des 🍪<span>',
                    description: "On aimerait bien vous accompagner pendant votre visite...<br><br> Nos cookies rendront votre expérience utilisateur bien plus riche, ils sont adaptés à vos centres d’intérêts et nous permettront de réaliser des statistiques afin d'améliorer votre visite.<br><br> Cliquez sur \"<span class=\"text-uppercase font-weight-bold color_3\">Accepter</span>\" pour autoriser les cookies<br> ou cliquez sur \"Paramètres\" pour choisir vos cookies.",
                    primary_btn: {
                        text: 'Accepter Tout'
                    },
                }
            }
        }
    }
});
$(document).ready(() => {

    //bouton getBackButton
    let getBackButtonElement = document.getElementById('getBackButton');
    if(getBackButtonElement)
    {
        getBackButtonElement.addEventListener('click', ()=>{
            window.history.back();
        });
    }

    let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    let tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl)
    })

    let toastParent = document.querySelector('#toast-parent');
    let notificationType = toastParent.dataset.notificationType;
    LoadToast(notificationType, '0170923131', '0892232388')

    if (document.getElementById('recrutement_form')) {
        document.getElementById('recrutement_form').addEventListener('submit', (event) => {
            event.preventDefault()
            let form = document.getElementById('recrutement_form');
            let data = new FormData(form);
            let error = 0;
            for (let [key, value] of data) {
                if (key !== "recrutement_form[g-recaptcha-response]" && value === "" || value == null) {
                    console.log(key)
                    error++;
                }
            }
            if (error === 0) {
                grecaptcha.ready(function () {
                    let gRecaptchaResponse = document.getElementById('recrutement_form_g-recaptcha-response')
                    grecaptcha.execute('6LekEr8fAAAAADp4nz5TCNow3IlbV6Tj1jSk3nv_', {action: 'submit'}).then(function (token) {
                        gRecaptchaResponse.value = token
                        form.submit();
                    });
                })
            } else {

            }
        })
    }
    const labels = document.querySelectorAll('.label_cst');
    labels?.forEach((el) => {
        el.classList.remove('col-sm-2')
    });
})
window.addEventListener('resize', () => {
    checkWindowSize()
});

function checkWindowSize() {
    if (window.matchMedia('(min-width: 576px)').matches) {
        let precedentLink = document.querySelector('.pagination .page-item .page-link.prev');
        if (precedentLink) {
            precedentLink.textContent = '<< précédent';
        }
        let suivantLink = document.querySelector('.pagination .page-item .page-link.next');
        if (suivantLink) {
            suivantLink.textContent = 'suivant >>';
        }
    } else {
        let precedentLink = document.querySelector('.pagination .page-item .page-link.prev');
        if (precedentLink) {
            precedentLink.textContent = '<<';
        }
        let suivantLink = document.querySelector('.pagination .page-item .page-link.next');
        if (suivantLink) {
            suivantLink.textContent = '>>';
        }
    }
}

//======================================================================
// Override default style of cookieConsent
// Should be always after declaration of initCookie()
//======================================================================
import '../css/cookie-consent/style.scss'
import * as bootstrap from "bootstrap";
// ======================================================================

$(() => {
    $('#recherche-voyant').select2({
        width: '100%',
        minimumInputLength: 3,
        ajax: {
            delay: 500,
            dataType: 'json',
            url: (params) => {
                return Routing.generate($('#recherche-voyant').data('ajax-search-route'), {'term': params.term});
            },

            processResults: data => ({
                results: data
            })
        },
        templateResult: function (obj) {
            if (obj.loading) {
                return obj.text;
            }
            return $(`<a href="${Routing.generate('operateur_detail', {'slug': obj.slug})}">${obj.nickname}</a>`);
        }
    });

    Array.from(document.querySelectorAll('.modal'))
        .filter(e => e.hasAttribute('data-show'))
        .forEach(e => $(e).modal())
})

// Affichage Popup Promotion sortie site
$(document).ready(function () {
    // RAJOUTER UN COOKIE de 30jours
    function setCookie(sName, sValue) {
        var today = new Date(), expires = new Date();
        expires.setTime(today.getTime() + (30 * 24 * 60 * 60 * 1000));
        document.cookie = sName + "=" + encodeURIComponent(sValue) + ";expires=" + expires.toGMTString();
    }

    // RECUPERE UN COOKIE
    function getCookie(sName) {
        var cookContent = document.cookie, cookEnd, i, j;
        var sName = sName + "=";

        let c;
        for (i = 0, c = cookContent.length; i < c; i++) {
            j = i + sName.length;
            if (cookContent.substring(i, j) === sName) {
                cookEnd = cookContent.indexOf(";", j);
                if (cookEnd === -1) {
                    cookEnd = cookContent.length;
                }
                return decodeURIComponent(cookContent.substring(j, cookEnd));
            }
        }
        return null;
    }

    if (window.innerWidth > 767) {
        $(document).mouseleave(function () {
            let cookie;
            cookie = getCookie("closeModal");
            if (cookie === null) {
                $('#modal-ecran').modal('show');
                setCookie("closeModal", true);
            }
        });

        $(".close").click(function () {
            $('#modal-ecran').modal('hide');
            setCookie("closeModal", true);
        });
    }
});
// Fin d'affichage Popup Promotion sortie site

// FORMULAIRE VERSION TABLETTE - MOBILE
$(".toggle-recherche").click(function () {
    $(".recherche-mobile").toggleClass("recherche-close", "recherche-open");
});

$(document).ready(function () {

    //////////////
    // Notation //
    //////////////
    var srcIn = 'star fa fa-star yellow'; //image au survol
    var srcOut = 'star fa fa-star'; // image non survolée

    // Obtenir id numérique des étoiles au format star_numero
    function idNum(id) {
        var id = id.split('_');
        var id = id[1];
        return id;
    }

    // Survol des étoiles
    $('.star').hover(function () {
        var id = idNum($(this).attr('id')); // id numérique de l'étoile survolée
        var nbStars = $('.star').length; // Nombre d'étoiles de la classe .star
        var i; // Variable d'incrémentation
        for (i = 1; i <= nbStars; i++) {
            if (i <= id) $('#star_' + i).attr({class: srcIn});
            else if (i > id) $('#star_' + i).attr({class: srcOut});
            if (i == id) $('#avis_form_rating').attr({value: i}); // affectation de la note au formulaire
        }
    }, function () {
    });
});

//////////////////////////
// Scroll detail voyant //
//////////////////////////
$('.nav-up').click(function (e) {
    e.preventDefault()
    $('.nav-item').removeClass('active');
    $(this).parent().addClass('active');
    var page = $(this).attr('href');
    var speed = 750; // gérer la vitesse de défliement

    // Ici on retranche la hauteur du bandeau
    $('html,body').animate(
        {scrollTop: $(page).offset().top - 130},
        speed
    );
});

//////////////////////////////////////
// Changement de jour planning next //
//////////////////////////////////////
$(document).ready(function () {
    //NEXT
    $('.slick-next').click(function () {
        if ($('.slick-next').attr("aria-disabled") == "true") {
            $('.jour-suivant').removeClass("d-none");
            $('.jour-suivant').addClass("d-block");
        } else {
            $('.jour-suivant').removeClass("d-block");
            $('.jour-suivant').addClass("d-none");
        }
    });
    if ($('.slick-next').attr("aria-disabled") == "true") {
        $('.jour-suivant').removeClass("d-none");
        $('.jour-suivant').addClass("d-block");
    } else {
        $('.jour-suivant').removeClass("d-block");
        $('.jour-suivant').addClass("d-none");
    }
    $('.slick-prev').click(function () {
        if ($('.slick-next').attr("aria-disabled") == "true") {
            $('.jour-suivant').removeClass("d-block");
            $('.jour-suivant').addClass("d-none");
            $('.slick-next').attr("aria-disabled", "false");
        }
    });

    //PREV
    $('.slick-prev').click(function () {
        if ($('.slick-prev').attr("aria-disabled") == "true") {
            $('.jour-prev').removeClass("d-none");
            $('.jour-prev').addClass("d-block");
        } else {
            $('.jour-prev').removeClass("d-block");
            $('.jour-prev').addClass("d-none");
        }
    });
    if ($('.slick-prev').attr("aria-disabled") == "true") {
        $('.jour-prev').removeClass("d-none");
        $('.jour-prev').addClass("d-block");
    } else {
        $('.jour-prev').removeClass("d-block");
        $('.jour-prev').addClass("d-none");
    }
});