import * as CookieConsent from 'vanilla-cookieconsent/dist/cookieconsent.umd.js';
import 'vanilla-cookieconsent/dist/cookieconsent.css';
const object = require('lodash/fp/object')
export default function initCookie(UConfiguration){

    let defaultConfig = {
        disablePageInteraction: true,

        general : {
            force_consent : true,
            cookie_expiration : 395,
            google_tag_id : "Undefined",
        },
        gui_options: {
            consent_modal: {
                layout: 'cloud',
                position: 'middle center',
                transition: 'slide',
                swap_buttons: false
            },
            settings_modal: {
                layout: 'box',
                transition: 'slide'
            }
        },
            languages: {
                fr: {
                    consent_modal: {
                        title: 'Ce site utilise des cookies',
                        description: "Cliquez sur 'Accepter' pour autoriser tous les cookies ou cliquez sur 'Paramètres' pour choisir vos cookies.",
                        primary_btn: {
                            text: 'Accepter',
                            role: 'accept_all'
                        },
                        secondary_btn: {
                            text: 'Paramètres',
                            role: 'settings'
                        },
                        accept_necessary_btn: 'Refuser'
                    },
                    settings_modal: {
                        title: 'Paramétrage des cookies',
                        save_settings_btn: "Enregistrer",
                        accept_all_btn: "Accepter tout",
                        reject_all_btn: "Refuser tout",
                        close_btn_label: "Fermer",

                    }
                }

        },
    };

    const onConsentLogic = ({ cookie }) => {
        if (CookieConsent.acceptedCategory('analytics')) {
            CookieConsent.loadScript('https://www.googletagmanager.com/gtag/js?id=' + defaultConfig.general.google_tag_id, function () {
                window.dataLayer = window.dataLayer || [];
                function gtag() {
                    dataLayer.push(arguments);
                }
                gtag('js', new Date());
                gtag('config', defaultConfig.general.google_tag_id);
            });
        }
    
        if (UConfiguration?.types && UConfiguration.types.length > 0) {
            UConfiguration.types.forEach(type => {
                if (CookieConsent.acceptedCategory(type.block.toggle.value)) {
                    type.onConsent();
                }
            });
        }
    };
    
    const onChangeLogic = () => {
        if (UConfiguration?.types && UConfiguration.types.length > 0) {
            UConfiguration.types.forEach(type => {
                if (CookieConsent.acceptedCategory(type.block.toggle.value)) {
                    type.onChange();
                }
            });
        }
    };

    defaultConfig = object.merge(defaultConfig, UConfiguration.params)

    let blocks = [
        {
            title : "Utilisation des cookies",
            description: 'Vous trouverez ci-dessous les différents cookies appliqués au site et leur utilisation.'
        },
        {
            title : "Cookies obligatoires au bon fonctionnement du site",
            description: 'Ces cookies strictements nécessaires sont indispensables et non soumis à votre consentement.',
            linkedCategory: 'necessary',
        },
        {
            title : "Google Analytics",
            description: 'En plus des cookies techniques strictement nécessaires à la navigation, nous utiliserons, si vous l\'acceptez, Google analytics afin d\'analyser le trafic sur le site et réaliser des statistiques d\'audience.',
            linkedCategory: 'analytics'
        }
    ]

    if(UConfiguration.types && UConfiguration.types.length > 0) {
        UConfiguration.types.forEach(type => blocks.push(type.block))
    }

    CookieConsent.run({
    
        onConsent: onConsentLogic,
        onChange: onChangeLogic,

    disablePageInteraction: defaultConfig.disablePageInteraction,

    guiOptions: {
        consentModal: {
            layout: defaultConfig.gui_options.consent_modal.layout,
            position: defaultConfig.gui_options.consent_modal.position,
            transition: defaultConfig.gui_options.consent_modal.transition,
            swap_buttons: defaultConfig.gui_options.consent_modal.swap_buttons
        }
    },

        categories: {
            analytics: {
                enabled: false,
                readOnly: false
            },
            necessary: {
                enabled: true,
                readOnly: true
            },
            mandatory: {
                enabled: false,
                readOnly: false
            },
            mandatory_read_only: {
                enabled: false,
                readOnly: true
            },
            enabled_mandatory: {
                enabled: true,
                readOnly: false
            }
        },
        
        language: {
            default: 'fr',
            translations: {
                fr: {
                    consentModal: {
                        title: defaultConfig.languages.fr.consent_modal.title,
                        description: defaultConfig.languages.fr.consent_modal.description,
                        acceptAllBtn: defaultConfig.languages.fr.consent_modal.primary_btn.text,
                        acceptNecessaryBtn: defaultConfig.languages.fr.consent_modal.accept_necessary_btn,
                        showPreferencesBtn: defaultConfig.languages.fr.consent_modal.secondary_btn.text
                    },
                    preferencesModal: {
                        title: defaultConfig.languages.fr.settings_modal.title,
                        acceptAllBtn: defaultConfig.languages.fr.settings_modal.accept_all_btn,
                        acceptNecessaryBtn: defaultConfig.languages.fr.settings_modal.reject_all_btn,
                        savePreferencesBtn: defaultConfig.languages.fr.settings_modal.save_settings_btn,
                        closeIconLabel: defaultConfig.languages.fr.settings_modal.save_settings_btn,
                        sections: blocks
                    }
                }
            }
        }
    });
}