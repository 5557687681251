import $ from "jquery";
window.$ = window.jQuery = $;
import "bootstrap-material-design/js/checkbox";
import "bootstrap-material-design/js/checkboxInline";
import "bootstrap-material-design/js/collapseInline";
import "bootstrap-material-design/js/file";
import "bootstrap-material-design/js/radio";
import "bootstrap-material-design/js/radioInline";
import "bootstrap-material-design/js/select";
import "bootstrap-material-design/js/switch";
import "bootstrap-material-design/js/text";
import "bootstrap-material-design/js/textarea";
// menu double click issue
// import "bootstrap-material-design/js/dropdown"; // NE PAS DECOMMENTER !
import "bootstrap-material-design/js/drawer";
import "bootstrap-material-design/js/ripples";
import "bootstrap-material-design/js/autofill";
import "bootstrap-material-design/js/bootstrapMaterialDesign";

$(() => {
    $('body').bootstrapMaterialDesign();
})