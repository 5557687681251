

let toastCall = document.getElementById('toast-call');
toastCall.style.width = '100%';

document.getElementById('toast-call').addEventListener('click', () => {
    sessionStorage.setItem('alreadyCall', '1');
    hideToast()
})

document.getElementById('toast-close').addEventListener('click', () => {
    hideToast()

})
let toastPromise = function(title, content, time, img, tel, button) {
    return new Promise((resolve) => {
        setTimeout(() => {
            showToast(title, content, time, img, tel, button)
        }, 30000);
        resolve();
    })
}

function showToast(title, content, time, img, tel, button) {
    if (img) $('#toast-img').attr('src', img)
    else $('#toast-img').hide()
    if (tel) $('#toast-call').attr("href", tel)

    $('#toast-call').text(button)
    $('#toast-title').html(title)
    $('#toast-content').html(content)
    $('#toast-time').html(time)
    $('#toast-parent').fadeIn(500)
}

function hideToast() {
    $('#toast-parent').fadeOut()
}

export default function LoadToast(notificationType, phoneNumberPrivate, phoneNumberAudiotel) {
    let alreadyCall = sessionStorage.getItem('alreadyCall');
    notificationType = parseInt(notificationType)

    if(notificationType == 1){
        $('#toast-image-audiotel').hide()
    }

    if (!alreadyCall) {

        $.get(`/popup/random/${notificationType}`, function(data) {
            if(data !== null){
            function getRatingAverageInStars(data) {
                let stars = '';
                let full_class = ''
                if(notificationType == 2){
                    full_class = ' yellow-audiotel'
                } else if(notificationType == 1){
                    full_class = ' yellow-private'
                }

                let empty_class = ''
                for (let i = 1; i < 6;) {
                    if (data >= i){
                        stars += "<i class='bi bi-star-fill" + ( full_class ) + "'></i>";
                        i++;
                    }
                    else if (data > i-1){
                        stars += "<i class='bi bi-star-half" + ( full_class ) + "'></i>";
                        i++;
                    }
                    else {
                        stars += "<i class='bi bi-star" + (empty_class) + "'></i>";
                        i++;
                    }
                }
                return stars
            }
            function getButtonByDevice(){
                if( navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i)) {
                    return 'Appeler ' + data.nickname
                } else {
                    return 'Consulter ' + data.nickname
                }
            }

            function getBadgeByType(notificationType) {
                let badge = '';
                switch (notificationType) {
                    case 2:
                        badge = "<span class=\"badge badge-info audiotel\"><i class=\"fa fa-phone\"></i> Audiotel</span>"
                        break;
                    case 1:
                        badge = "<span class=\"badge badge-info private\"><i class=\"fa fa-credit-card\"></i> Privée</span>"
                        break;
                }
                return badge;
            }
            function getPhoneNumberByType(notificationType) {
                let phoneNumber = ''
                switch (notificationType) {
                    case 2:
                        phoneNumber = phoneNumberAudiotel
                        break;
                    case 1:
                        phoneNumber = phoneNumberPrivate
                        break;
                }
                return phoneNumber
            }
            function getAttributesButtonPhoneByDevice(){
                if( navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i)) {
                    return 'tel:' + getPhoneNumberByType(notificationType)
                } else {
                    return (window.location.protocol + "//" + window.location.host + "/" + "voyants-mediums/detail-" + data.slug).toString()
                }
            }
            function httpGet(url) {
                let xmlHttp = new XMLHttpRequest();
                xmlHttp.open("GET", url, false);
                xmlHttp.send(null);
                return xmlHttp.status;
            }

            let title = data.nickname + " " + getRatingAverageInStars(data.ratingAverage);
            let content = "<b>" + data.nickname + "</b> est disponible tout de suite ! <br/>Appeler " + data.nickname + " au " + getPhoneNumberByType(notificationType) + "<br/>"
            let time = getBadgeByType(notificationType);
            let tel = getAttributesButtonPhoneByDevice();
            let button = getButtonByDevice();
            let toastImage = document.querySelector('#toast-img');
            let defaultAvatarUrl = toastImage.dataset.defaultAvatarUrl;
            let imgUrl = toastImage.dataset.imagePath.replace('image_file', data.imageName)
            let img = (httpGet(imgUrl) === 500 || httpGet(imgUrl) === 404) ? defaultAvatarUrl : imgUrl


            toastPromise(title, content, time, img, tel, button).then(() => {
                setTimeout(() => {
                    hideToast()
                }, 120000)
            })
            }
        });
    }

}